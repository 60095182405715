@import 'styles/variables.scss';

.boxApp {
  margin: auto;
  .body {
    padding: 10px 0px;
    width: 100%;
    min-height: calc(100vh - 402px);
    @media (max-width: 768px) {
      padding: auto;
    }
  }
}
