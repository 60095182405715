/*COLORS */
$color-white: #fff;
$color-light-gray: #f9f9f9;
$color-clean-gray: #e6e6e6;
$color-gray: #c5c5c5;
$color-dark-gray: #333;
$color-dark-gray-medium: #555;
$color-dark-gray-less: #777;
$color-blue: #00008f;
$color-light-blue: #0000ff;
$color-clean-blue: #009ae5;
$color-red-error: #cd0a0a;
$color-red-banner: #f6424c;
$color-red-error-border: #b94a48;
$color-orange: #f07662;
$color-gray-light-prod: #ffffff;
$border-color-input: #e4e4e4;

/* FONTS */
$font-regular: 'Myriad Pro Regular';
$font-light: 'Myriad Pro Light';
$font-publicBanner: 'Publico Banner';
$font-publicoBlack: 'Publico Black';
$font-sansProLight: 'Source Sans Pro Light';
$font-sansProRegular: 'Sans Pro Regular';
$font-latoRegular: 'latoregular';
$font-latoBlack: 'latoblack';
$font-latoLight: 'latolight';
$font-latoBold: 'latobold';

$color-blue-light: #252b82;
$color-blue-hover: #23527c;
$color-blue-dark: #010090;
$color-brown: #9a7850;
$color-brown-light: #bcb1a5;
$color-gold: #c29d5c;
$color-gray-dark: #54606c;
$color-gray: #cad1d6;
$color-gray-light: #eaeef0;
$color-gray-light2: #bababa;
$color-gray-light3: #878787;
$color-gray-light4: #f8f8f8;
$color-gray-light5: #8c8c8c;
$color-gray-background: #f5f5f5;
$color-black: #333333;
$color-error-red: #aa0000;
$color-error-red-hover: #cc0000;
$color-error-red-alert: #e74c3c;
$color-red-button: #ff1721;
$color-success-green: #5c7658;
$color-success-green2: #7cb342;
$color-success-green-hover: #6c8668;
$color-yellow-action: #f7b600;
$color-orange-hover: #f5812b;
$color-orange-light: #e74c3c;
$color-organge-dark: #ef8400;
$color-green-dark: #488f06;
$color-green-confirmation: #13b013;
$color-green-light: #f1fbd1;
$color-green-medium: #bce830;

$font-size-itemTitle: 22px;
$font-size-itemTitleMobile: 20px;
$font-size-itemContent: 16px;
$font-size-itemContentMobile: 12px;

/* WIDTH - GRID */
$width-small: 576px;
$width-medium: 768px;
$width-large: 992px;
$width-extra-large: 1200px;
