@import '../../styles/variables';

.wrapLoading {
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba($color: #228, $alpha: 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .loadingCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 80px;
    background-color: $color-white;
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
    .loadingMsg {
      margin-top: 15px;
      color: $color-blue;
    }
  }
}
